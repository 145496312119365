import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Grid,
  Typography,
  Dialog,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  MenuItem,
  useTheme
} from '@mui/material';
import routes from 'src/next/navigation/routes';
import { mapValues, range } from 'lodash';
import { Box } from '@mui/system';
import { ASSIGNMENT_TYPES, CERTIFICATION_STEP_TYPES, ROLES } from 'src/constants';
import { Download as DownloadIcon } from 'react-feather';
import styled from '@emotion/styled';
import { LoadingButton } from '@mui/lab';
import ReviewsList from './ReviewsList';
import PartnerList, { PartnerCount, StyledSelect } from './PartnerList';
import PartnerFilters from './PartnerFilters';
import { PeriodsConfig } from '../certification/periodsConfig';
import Page from '../../components/Page';
import useStore from '../store';
import useDownload from '../util/useDownload';
import { filterPartnersByAssignmentTypes } from '../certification/certificationUtil';
import useDialogState from '../util/useDialogState';

const { PSA, PSM } = ASSIGNMENT_TYPES;
const DEFAULT_FILTERS = {
  industries: [],
  assessmentTypes: [],
  certificationStatuses: [],
  certificationYears: [],
  isCertifiedInCurrentYear: false
};
const START_YEAR = 2020;
const REPORT_START_YEAR = 2024;
const PERIOD_BY_POSITION = {
  1: 'February',
  2: 'August'
};
const SORT_OPTIONS = [
  { label: 'A-Z', order: 'asc' },
  { label: 'Z-A', order: 'desc' },
  { label: 'Updated At (Newest first)', order: 'desc', sortBy: 'updatedAt' },
  { label: 'Updated At (Oldest first)', order: 'asc', sortBy: 'updatedAt' }
];

const StyledButton = styled(props => (
  <LoadingButton {...props} size='medium' />
))`
  margin-left: ${({ theme }) => theme.spacing(1)};
`;

function useDownloadReportByType(stepType, stepPosition, cycleYear, assessmentType) {
  const { getCertificationsReport } = useStore(state => state.certification);
  const month = PERIOD_BY_POSITION[stepPosition];
  const REPORT_FILENAME = stepType === 'evaluation'
    ? `Assessment_${cycleYear}_${month}_${assessmentType}_report.xlsx`
    : `Assessment_${stepType}_${assessmentType}_report.xlsx`;

  return useDownload(() => getCertificationsReport(stepType, stepPosition, cycleYear, assessmentType), REPORT_FILENAME);
}

function StaffHeader({
  theme,
  isMyReviewsView,
  setIsMyReviewsView,
  filteredPartnersCount,
  isPsmAssignmentListEmpty,
  sortOrder,
  setSortOrder
}) {
  return (
    <Grid
      item
      xs={12}
      container
      justifyContent='space-between'
      alignItems='center'
      mb={3}
    >
      <Box>
        {
          !isPsmAssignmentListEmpty &&
            <>
              <Typography
                variant='subtitle1'
                component='span'
                color= {isMyReviewsView && theme.palette.grey[600]}
                onClick={() => setIsMyReviewsView(false)}
                sx={{ cursor: 'pointer' }}
              >
                My Partners
              </Typography>
              <PartnerCount disabled={isMyReviewsView}>
                {filteredPartnersCount}
              </PartnerCount>
              &nbsp;&nbsp;
            </>
        }
      </Box>
      <Box>
        <Typography variant='bodyBold'>
          Sort by:
        </Typography>
        <StyledSelect
          value={sortOrder}
          onChange={event => setSortOrder(event.target.value)}
        >
          {SORT_OPTIONS.map(option => (
            <MenuItem
              key={option.label}
              value={option}
            >
              <Typography>
                {option.label}
              </Typography>
            </MenuItem>
          ))}
        </StyledSelect>
      </Box>
    </Grid>
  );
}

export default function PartnerMain({ user }) {
  const theme = useTheme();
  const { partners, getPartners } = useStore(state => state.partner);
  const { assessmentRanges, currentDate } = useStore(state => state.settings);
  const [isMyReviewsView, setIsMyReviewsView] = useState(false);
  const [sortOrder, setSortOrder] = useState(SORT_OPTIONS[0]);
  const [isReportDownload, openReportDownload, closeReportDownload] = useDialogState();
  const isAdmin = user?.role === ROLES.ADMIN;
  const isStaff = user?.role === ROLES.STAFF;
  const isPsTeamMember = user.certificationAssignments.some(it => [PSA, PSM].includes(it.type));
  const periodsConfig = new PeriodsConfig(assessmentRanges, currentDate);
  const periods = periodsConfig.getPeriods();

  const certifications = useMemo(() => partners.flatMap(partner => {
    return partner.certifications;
  }), [partners]);

  const currentYear = periodsConfig.currentDate.getFullYear();
  const years = range(START_YEAR, currentYear + 1);
  const reportYears = range(REPORT_START_YEAR, currentYear + 1);
  const reportTypes = [
    { label: 'Certification', value: CERTIFICATION_STEP_TYPES.EVALUATION },
    { label: 'Baseline', value: CERTIFICATION_STEP_TYPES.BASELINE }
  ];

  const {
    filteredPartners: partnersByPsmAssignment
  } = filterPartnersByAssignmentTypes(partners, [PSA, PSM], user.id);

  const isPsmAssignmentListEmpty = partnersByPsmAssignment.length === 0;

  const initialPartnersList = isAdmin ? partners : partnersByPsmAssignment;

  const [filters, setFilters] = useState(DEFAULT_FILTERS);
  const [search, setSearch] = useState('');
  const { user: loggedInUser } = useStore(state => state.auth);
  const [selectedStepPosition, setSelectedStepPosition] = useState('');
  const [selectedCycleYear, setSelectedCycleYear] = useState('');
  const [selectedAssessmentType, setSelectedAssessmentType] = useState('');
  const [selectedStepType, setSelectedStepType] = useState(CERTIFICATION_STEP_TYPES.EVALUATION);
  const [downloadReport, isDownloadingReport] = useDownloadReportByType(selectedStepType, selectedStepPosition, selectedCycleYear, selectedAssessmentType);

  const setReportType = value => {
    setSelectedStepType(value);
    clearSelections();
  };

  const clearSelections = () => {
    setSelectedStepPosition('');
    setSelectedCycleYear('');
    setSelectedAssessmentType('');
  };

  const handleReportDownloadClose = () => {
    setSelectedStepType(CERTIFICATION_STEP_TYPES.EVALUATION);
    clearSelections('');
    closeReportDownload();
  };

  const searchRegex = new RegExp(search, 'i');
  const {
    industries: industryFilters,
    assessmentTypes: assessmentTypeFilters,
    certificationYears: certificationYearFilters,
    isCertifiedInCurrentYear: isCertifiedInCurrentYearFilter
  } = mapValues(filters, f => typeof f === 'boolean' ? f : new Set(f));
  const certificationsByPartnerId = useMemo(
    () => initialPartnersList.reduce((acc, p) => acc.set(p.id, p.certifications), new Map()),
    [initialPartnersList]
  );

  const filteredPartners = initialPartnersList.filter(p => {
    const certifications = certificationsByPartnerId.get(p.id);
    const types = certifications.map(c => c.assessmentType.title);
    const isCertifiedInCurrentYear = certifications.some(c => c.isCertifiedInCurrentYear);
    const getYearsFromCertificationSteps = c => c.certificationSteps.map(s => new Date(s.createdAt).getFullYear());
    const certficationCycleYears = certifications.flatMap(getYearsFromCertificationSteps);

    return searchRegex.test(p.name) &&
      (!industryFilters.size || industryFilters.has(p.industry)) &&
      (!assessmentTypeFilters.size || types.some(t => assessmentTypeFilters.has(t))) &&
      (!isCertifiedInCurrentYearFilter || isCertifiedInCurrentYear) &&
      (!certificationYearFilters.size || certficationCycleYears.some(y => certificationYearFilters.has(y)));
  });
  const certificationPeriods = periods.map(({ title, position }) => ({ title, position }));
  const assessmentTypes = [...new Set(initialPartnersList.flatMap(p => p.certifications).map(c => c.assessmentType.title)).values()];
  const clearFiltersAndSearch = useCallback(() => {
    setFilters(DEFAULT_FILTERS);
    setSearch('');
  }, [setFilters, setSearch]);

  useEffect(() => {
    getPartners();
  }, [getPartners]);

  useEffect(() => {
    if (isPsmAssignmentListEmpty && !isAdmin) {
      setIsMyReviewsView(true);
    } else {
      setIsMyReviewsView(false);
    }
  }, [isPsmAssignmentListEmpty]);

  const isDownloadDisabled = selectedStepType === CERTIFICATION_STEP_TYPES.EVALUATION
    ? (!selectedStepPosition || !selectedCycleYear || !selectedAssessmentType)
    : !selectedAssessmentType;

  return (
    <Page title='MLT Racial Equity at Work Online Portal'>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          container
          justifyContent='space-between'
          alignItems='center'
        >
          <Typography variant='h1' mb={2}>
            Partner Management
          </Typography>
          <Box>
            {
              loggedInUser?.role === ROLES.ADMIN &&
              <>
                <StyledButton
                  onClick={openReportDownload}
                  startIcon={<DownloadIcon alt="download" />}
                >
                  Assessment Report
                </StyledButton>
                <StyledButton
                  to={routes.ADMINISTRATION_USERS}
                >
                  Edit Users
                </StyledButton>

                <Dialog
                  open={isReportDownload}
                  fullWidth
                  onClose={handleReportDownloadClose}
                >
                  <Box p={4} mr={2}>
                    <Grid mb={3}>
                      <Typography variant='modalHeadline'>
                        Download Report
                      </Typography>
                    </Grid>
                    <Box>

                      <Typography variant='body' display='block' mb={1} ml={1}>
                        Report type
                      </Typography>
                      <RadioGroup
                        row
                        sx={{ ml: 1, mb: 1 }}
                        value={selectedStepType}
                        onChange={event => setReportType(event.target.value)}
                      >
                        {reportTypes.map(({ label, value }) => (
                          <FormControlLabel
                            key={value}
                            value={value}
                            label={label}
                            control={<Radio />}
                          />
                        ))}
                      </RadioGroup>

                      <Box>
                        {
                          selectedStepType === CERTIFICATION_STEP_TYPES.EVALUATION &&
                            <>
                              <Typography variant='body' display='block' mb={1} ml={1}>
                                Year
                              </Typography>
                              <StyledSelect
                                fullWidth
                                value={selectedCycleYear}
                                onChange={event => setSelectedCycleYear(event.target.value)}
                              >
                                {reportYears.map(year => (
                                  <MenuItem key={year} value={year}>
                                    <Typography>{year}</Typography>
                                  </MenuItem>
                                ))}
                              </StyledSelect>
                              <Typography variant='body' display='block' mt={2} mb={1} ml={1}>
                                Certification Period
                              </Typography>
                              <StyledSelect
                                fullWidth
                                value={selectedStepPosition}
                                onChange={event => setSelectedStepPosition(event.target.value)}
                              >
                                {certificationPeriods.map(({ title, position }) => (
                                  <MenuItem key={position} value={position}>
                                    <Typography>{title}</Typography>
                                  </MenuItem>
                                ))}
                              </StyledSelect>
                            </>
                        }
                      </Box>
                      <Typography variant='body' display='block' mt={2} mb={1} ml={1}>
                        Certification Program
                      </Typography>
                      <StyledSelect
                        fullWidth
                        value={selectedAssessmentType}
                        onChange={event => setSelectedAssessmentType(event.target.value)}
                      >
                        {assessmentTypes.map(type => (
                          <MenuItem key={type} value={type}>
                            <Typography>{type}</Typography>
                          </MenuItem>
                        ))}
                      </StyledSelect>
                      <Grid
                        container
                        justifyContent='center'
                        spacing={1}
                        mt={3}
                        mb={1}
                      >
                        <Grid item>
                          <LoadingButton
                            type='submit'
                            onClick={downloadReport}
                            loading={isDownloadingReport}
                            disabled={isDownloadDisabled}
                            startIcon={<DownloadIcon alt="download" />}
                          >
                            Download
                          </LoadingButton>
                        </Grid>
                        <Grid item>
                          <Button
                            variant='text'
                            onClick={handleReportDownloadClose}
                          >
                            Cancel
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </Dialog>
              </>
            }
          </Box>
        </Grid>
        {
          isStaff &&
            <StaffHeader
              theme={theme}
              isMyReviewsView={isMyReviewsView}
              setIsMyReviewsView={setIsMyReviewsView}
              isPsmAssignmentListEmpty={isPsmAssignmentListEmpty}
              filteredPartnersCount={filteredPartners.length}
              sortOrder={sortOrder}
              setSortOrder={setSortOrder}
            />
        }
        <Grid item md={8} xs={12}>
          {
            isMyReviewsView
              ? <ReviewsList partners={filteredPartners} sortOrder={sortOrder} filters={filters}/>
              : <PartnerList
                partners={filteredPartners}
                sortOrder={sortOrder}
                setSortOrder={setSortOrder}
                isAdmin={isAdmin}
                isPsTeamMember={isPsTeamMember}
                filters={filters}
                periods={periods}
                currentDate={currentDate}
              />
          }
        </Grid>
        <Grid item md={4} xs={12}>
          <PartnerFilters
            partners={partners}
            certifications={certifications}
            search={search}
            filters={filters}
            onFiltersChange={setFilters}
            onSearch={setSearch}
            onClear={clearFiltersAndSearch}
            years={years}
          />
        </Grid>
      </Grid>
    </Page>
  );
}
